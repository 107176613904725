import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const backgroundStyles = {
  gradientBackground: {
    backgroundImage: `linear-gradient(120deg, #0f172a 0%, #101e33 100%)`,
  },
};

const faqs = [
  {
    question: "What is NixArcade?",
    answer:
      "NixArcade is an online multiplayer gaming platform where players can enjoy classic board games with crypto rewards, including Solana.",
  },
  {
    question: "How do I start playing?",
    answer:
      "Create an account, link your wallet, and join a game. You can start a private game with friends or join a public match.",
  },
  {
    question: "Which games are available on NixArcade?",
    answer:
      "We offer popular games like Ludo, Chess, Monopoly, Tic Tac Toe, and Snakes & Ladders.",
  },
  {
    question: "Is NixArcade free to use?",
    answer:
      "Yes, the platform is free to join, but some games may have entry fees that contribute to the prize pool.",
  },
  {
    question: "What cryptocurrencies are supported?",
    answer: "Currently, we support Solana (SOL) for rewards and transactions.",
  },
  {
    question: "How do I withdraw my winnings?",
    answer:
      "Winnings are automatically transferred to your linked wallet. Ensure your wallet address is correct and compatible with Solana.",
  },
  {
    question: "Can I play with friends?",
    answer:
      "Yes, you can create private matches and invite friends using a unique game code.",
  },
  {
    question: "What happens if I lose a game?",
    answer:
      "If you lose a game, the entry fee (if applicable) is distributed to the winner as part of the prize pool.",
  },
  {
    question: "How secure is NixArcade?",
    answer:
      "We use advanced encryption and blockchain technology to ensure secure transactions and gameplay.",
  },
  {
    question: "Who do I contact for support?",
    answer:
      "You can reach out to us at support@nixarcade.fun for assistance with any issues or inquiries.",
  },
];

export default function FAQPage() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <motion.div
      className="min-h-screen"
      style={backgroundStyles.gradientBackground}
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.8 } },
      }}
    >
      {/* Navbar */}
      <nav
        className={`fixed w-full z-50 transition-all duration-300 ${
          isScrolled
            ? "bg-purple-900/80 backdrop-blur-md shadow-lg"
            : "bg-transparent"
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <img
                src="/mainlogo.png"
                alt="NixArcade Logo"
                className="h-8 w-8 mr-2"
              />
              <span className="text-xl font-bold text-white">NixArcade</span>
            </div>
            <div className="hidden md:flex items-center space-x-4">
              <a
                href="/"
                className="text-gray-300 hover:text-pink-400 transition-colors"
              >
                Home
              </a>
              <a
                href="#faq"
                className="text-gray-300 hover:text-pink-400 transition-colors"
              >
                FAQ
              </a>
              <a
                href="/terms"
                className="text-gray-300 hover:text-pink-400 transition-colors"
              >
                Terms
              </a>
              <a
                href="https://nixarcade.fun"
                className="text-gray-300 hover:text-pink-400 transition-colors"
              >
                Games
              </a>
            </div>
          </div>
        </div>
      </nav>

      {/* FAQ Section */}
      <motion.section
        id="faq"
        className="pt-24 pb-16 px-4"
        initial="hidden"
        animate="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
        }}
      >
        <div className="max-w-5xl mx-auto">
          <h1 className="text-4xl font-bold text-center mb-8 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
            Frequently Asked Questions
          </h1>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                initial="hidden"
                animate="visible"
                exit="hidden"
                whileHover={{ scale: 1.02 }}
                className="bg-purple-800/50 p-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
              >
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFAQ(index)}
                >
                  <h2 className="text-lg font-semibold text-white">
                    {faq.question}
                  </h2>
                  <motion.span
                    animate={{ rotate: activeIndex === index ? 180 : 0 }}
                    className="text-pink-400 transition-transform"
                  >
                    ▼
                  </motion.span>
                </div>
                {activeIndex === index && (
                  <motion.p
                    className="mt-4 text-gray-300"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.3 },
                    }}
                    exit={{ opacity: 0, y: -10 }}
                  >
                    {faq.answer}
                  </motion.p>
                )}
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* Footer */}
      <footer className="py-8 bg-purple-900/30">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mt-4 text-gray-600 text-sm">
            © 2024 NixArcade. All rights reserved.
          </div>
        </div>
      </footer>
    </motion.div>
  );
}
