import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Lock,
  Coins,
  Share2,
  Users,
  Shield,
  Wallet,
  Trophy,
  Star,
  Globe,
  CreditCard,
  Zap,
  Puzzle,
  RefreshCw,
  UserCheck,
} from "lucide-react";

const backgroundStyles = {
  gradientBackground: {
    backgroundImage: `linear-gradient(120deg, #0f172a 0%, #101e33 100%)`,
  },
};

export default function LandingPage() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleGetStarted = () => {
    window.location.href = "https://nixarcade.fun";
  };

  const games = [
    {
      title: "Ludo",
      description: "Classic board game with random chance and strategy",
      image: "/ludo.png",
      type: "Luck & Strategy",
    },
    {
      title: "Chess",
      description: "Pure skill-based strategic game of mind",
      image: "/chess.png",
      type: "Skill-Based",
    },
    {
      title: "Monopoly",
      description: "Economic strategy and luck-based property trading",
      image: "/monopoly.png",
      type: "Luck & Strategy",
    },
    {
      title: "Tic Tac Toe",
      description: "Quick, simple strategic game",
      image: "/ttt.png",
      type: "Skill-Based",
    },
    {
      title: "Snakes & Ladders",
      description: "Pure luck-based classic board game",
      image: "/snl.png",
      type: "Luck-Based",
    },
  ];

  const fadeInVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      className="min-h-screen"
      style={backgroundStyles.gradientBackground}
    >
      {/* Navbar */}
      <nav
        className={`fixed w-full z-50 transition-all duration-300 ${
          isScrolled
            ? "bg-purple-900/80 backdrop-blur-md shadow-lg"
            : "bg-transparent"
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <img
                src="/mainlogo.png"
                alt="NixArcade Logo"
                className="h-8 w-8 mr-2"
              />
              <span className="text-xl font-bold text-white">NixArcade</span>
            </div>
            <div className="hidden md:flex items-center space-x-4">
              <a
                href="#games"
                className="text-gray-300 hover:text-pink-400 transition-colors"
              >
                Games
              </a>
              <a
                href="#how-it-works"
                className="text-gray-300 hover:text-pink-400 transition-colors"
              >
                How It Works
              </a>
              <button
                onClick={handleGetStarted}
                className="px-4 py-2 rounded-lg transition-all 
                hover:scale-105 
                bg-gradient-to-r from-purple-600 to-pink-500 text-white"
              >
                Play Now
              </button>
            </div>
            <div className="md:hidden">
              <button className="text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={fadeInVariants}
        className="pt-24 pb-16 px-4"
      >
        <div className="max-w-7xl mx-auto">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
              Multiplayer Board Games
              <span className="block bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
                With Crypto Rewards
              </span>
            </h1>
            <p className="text-lg text-gray-400 mb-6 max-w-2xl mx-auto">
              Play classic board games with friends, compete for Solana prizes,
              and enjoy private multiplayer experiences
            </p>
            <button
              onClick={handleGetStarted}
              className="px-6 py-3 rounded-lg transition-all 
              hover:scale-105 
              bg-gradient-to-r from-purple-600 to-pink-500 text-white"
            >
              Get Started
            </button>
          </div>
        </div>
      </motion.section>

      {/* Games Section */}
      <motion.section
        id="games"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={fadeInVariants}
        className="py-16 bg-purple-900/20"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white">
              Our Game Collection
            </h2>
            <p className="mt-4 text-gray-400">
              Diverse games with mixed skill and luck elements
            </p>
          </div>

          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6">
            {games.map((game, index) => (
              <motion.div
                key={index}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={fadeInVariants}
                className="bg-purple-900/50 p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all"
              >
                <div className="flex justify-center mb-4">
                  <img
                    src={game.image}
                    alt={game.title}
                    className="w-24 h-24 object-contain rounded-lg"
                  />
                </div>
                <h3 className="text-xl font-semibold mb-2 text-center text-white">
                  {game.title}
                </h3>
                <p className="text-gray-400 text-center mb-2 text-sm">
                  {game.description}
                </p>
                <div className="text-center text-xs text-pink-400">
                  {game.type}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* How It Works Section */}
      <motion.section
        id="how-it-works"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={fadeInVariants}
        className="py-16"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white">
              How NixArcade Works
            </h2>
            <p className="mt-4 text-gray-400">
              Simple, secure, and fun gaming experience
            </p>
          </div>

          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6">
            {[
              {
                title: "Create Wallet",
                description: "Automatic in-game wallet created with Okto",
                icon: <Lock className="w-8 h-8 text-pink-500" />,
              },
              {
                title: "Share Game Code",
                description: "Create a private game, invite up to 4 players",
                icon: <Share2 className="w-8 h-8 text-pink-500" />,
              },
              {
                title: "Win & Withdraw",
                description: "Winner receives Solana directly to their wallet",
                icon: <Coins className="w-8 h-8 text-pink-500" />,
              },
            ].map((step, index) => (
              <motion.div
                key={index}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={fadeInVariants}
                className="bg-purple-900/50 p-6 rounded-2xl shadow-lg"
              >
                <div className="flex justify-center mb-4">{step.icon}</div>
                <h3 className="text-xl font-semibold mb-2 text-center text-white">
                  {step.title}
                </h3>
                <p className="text-gray-400 text-center text-sm">
                  {step.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* Future Roadmap */}
      <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInVariants}
        className="py-20"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-white">Our Roadmap</h2>
            <p className="mt-4 text-gray-400">
              Exciting developments coming to NixArcade
            </p>
          </div>

          <motion.div
            variants={fadeInVariants}
            className="grid md:grid-cols-3 gap-8"
          >
            {[
              {
                title: "More Games",
                description:
                  "Expanding our game library with community favorites",
                icon: <Puzzle className="w-8 h-8 text-pink-500" />,
              },
              {
                title: "Tournament Mode",
                description: "Competitive leagues with larger prize pools",
                icon: <Trophy className="w-8 h-8 text-pink-500" />,
              },
              {
                title: "Continuous Improvements",
                description: "Regular updates based on user feedback",
                icon: <RefreshCw className="w-8 h-8 text-pink-500" />,
              },
            ].map((roadmap, index) => (
              <motion.div
                key={index}
                variants={fadeInVariants}
                whileHover={{ scale: 1.05 }}
                className="bg-purple-900/50 p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <div className="flex justify-center mb-4">{roadmap.icon}</div>
                <h3 className="text-xl font-semibold mb-2 text-center text-white">
                  {roadmap.title}
                </h3>
                <p className="text-gray-400 text-center">
                  {roadmap.description}
                </p>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </motion.section>

      {/* Footer */}
      <footer className="py-8 bg-purple-900/30">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6 items-center">
            <a
              href="/terms"
              className="text-gray-500 hover:text-pink-400 transition-colors"
            >
              Terms of Service
            </a>
            <a
              href="/terms"
              className="text-gray-500 hover:text-pink-400 transition-colors"
            >
              Privacy Policy
            </a>
            <a
              href="/faqs"
              className="text-gray-500 hover:text-pink-400 transition-colors"
            >
              FAQs
            </a>
          </div>
          <div className="text-center mt-4 text-gray-600 text-sm">
            © 2024 NixArcade. All rights reserved.
          </div>
        </div>
      </footer>
    </motion.div>
  );
}
