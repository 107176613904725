import React from "react";
import { Link } from "react-router-dom";

export function TermsOfService() {
  return (
    <>
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <Link
                to="/"
                className="flex-shrink-0 flex items-center text-xl font-bold text-gray-900 hover:text-gray-600 transition"
              >
                NixArcade
              </Link>
            </div>
          </div>
        </div>
      </nav>
      <div className="min-h-screen bg-white text-black p-6">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold text-center mb-6">
            Terms of Service and Privacy Policy
          </h1>

          {/* Terms of Service Section */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-4">Terms of Service</h2>
            <p className="mb-4">
              Welcome to NixArcade ("we," "our," or "us"). By accessing or using
              the services provided by NixArcade, you agree to comply with and
              be bound by these Terms of Service ("Terms"). If you do not agree
              to these Terms, please refrain from using our services. NixArcade
              offers multiplayer board games integrated with cryptocurrency
              rewards, fostering competitive and secure gameplay experiences.
              Please read these Terms carefully before accessing or using our
              services.
            </p>
            <h3 className="text-xl font-bold mt-6 mb-2">1. Use of Services</h3>
            <p className="mb-4">
              NixArcade provides multiplayer gaming experiences with crypto
              rewards. To use our services, you must:
              <ul className="list-disc list-inside mt-2">
                <li>
                  Age Requirement: You must be at least 18 years of age or have
                  parental/guardian consent to use our platform. Compliance: You
                  agree to comply with all applicable laws and regulations while
                  using our services.
                </li>
                <li>
                  Compliance: You agree to comply with all applicable laws and
                  regulations while using our services.
                </li>
                <li>
                  Refrain from exploiting bugs or vulnerabilities in the games.
                </li>
                <li>
                  Account Responsibility: You are solely responsible for the
                  security and confidentiality of your account credentials. Any
                  activity under your account is your responsibility.
                </li>
              </ul>
            </p>
            <h3 className="text-xl font-bold mt-6 mb-2">2. User Accounts</h3>
            <p className="mb-4">
              When you create an account, you are responsible for maintaining
              the confidentiality of your login credentials. Any activities
              conducted through your account are your responsibility.
              <ul>
                <li>
                  Registration: To access certain features, you may need to
                  create an account by providing accurate and complete
                  information.
                </li>
                <li>
                  Security: Safeguard your login credentials. NixArcade is not
                  liable for unauthorized access or misuse of your account.
                </li>
                <li>
                  Termination: We reserve the right to suspend or terminate your
                  account for violation of these Terms or if fraudulent activity
                  is suspected.
                </li>
              </ul>
            </p>
            <h3 className="text-xl font-bold mt-6 mb-2">
              3. Payment and Rewards
            </h3>
            <p className="mb-4">
              Games involving cryptocurrency rewards follow strict guidelines:
              <ul className="list-disc list-inside mt-2">
                <li>
                  Gameplay: Our games are designed to blend skill and chance.
                  Some games may involve cryptocurrency rewards.
                </li>
                <li>
                  Prizes: Winners receive Solana (SOL) rewards, credited
                  directly to their designated wallet.
                </li>
                <li>
                  Fair Play: Users must adhere to ethical gaming practices.
                  Manipulation or cheating is strictly prohibited.
                </li>
                <li>
                  Disclaimer: We do not guarantee the availability or
                  uninterrupted operation of any game.
                </li>
                <li>
                  Rewards are credited directly to your wallet upon winning.
                </li>
                <li>
                  NixArcade is not responsible for delays due to blockchain
                  processing.
                </li>
                <li>
                  All transactions are final, and refunds are not provided.
                </li>
              </ul>
            </p>
            <h3 className="text-xl font-bold mt-6 mb-2">
              4. Limitation of Liability
            </h3>
            <p>
              NixArcade is not liable for:
              <ul>
                <li>Any losses incurred through gameplay or transactions.</li>
                <li>Errors, delays, or failures in blockchain processing.</li>
                <li>
                  Unauthorized access to your account resulting from user
                  negligence.
                </li>
              </ul>
            </p>
            <h3 className="text-xl font-bold mt-6 mb-2">5. Termination</h3>
            <p className="mb-4">
              NixArcade reserves the right to terminate or suspend your account
              if you violate these terms or engage in activities that harm the
              platform or its users.
            </p>
            <h3 className="text-xl font-bold mt-6 mb-2">
              6. Updates and Modifications
            </h3>
            <p>
              We may update or modify these Terms at our discretion. Continued
              use of our services after changes are posted constitutes
              acceptance of the updated Terms.
            </p>
          </section>

          {/* Privacy Policy Section */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-4">Privacy Policy</h2>
            <p className="mb-4">
              NixArcade is committed to protecting your privacy. This Privacy
              Policy explains how we collect, use, disclose, and safeguard your
              information when you visit or use our platform.
            </p>
            <h3 className="text-xl font-bold mt-6 mb-2">
              1. Information We Collect
            </h3>
            <p className="mb-4">
              We collect the following types of information:
              <ul className="list-disc list-inside mt-2">
                <li>Personal information: Name, email, wallet address.</li>
                <li>Usage data: Interaction logs, game statistics.</li>
                <li>
                  Device information: Browser type, IP address, operating
                  system.
                </li>
              </ul>
            </p>
            <h3 className="text-xl font-bold mt-6 mb-2">
              2. How We Use Your Information
            </h3>
            <p className="mb-4">
              The collected information is used to:
              <ul className="list-disc list-inside mt-2">
                <li>Provide and improve our services.</li>
                <li>Facilitate rewards and transactions.</li>
                <li>Analyze user behavior for platform optimization.</li>
              </ul>
            </p>
            <h3 className="text-xl font-bold mt-6 mb-2">3. Data Protection</h3>
            <p className="mb-4">
              We implement strict security measures to protect your data from
              unauthorized access, disclosure, or misuse. However, no system is
              completely secure, and we cannot guarantee the absolute safety of
              your information.
            </p>
            <h3 className="text-xl font-bold mt-6 mb-2">
              4. Third-Party Services
            </h3>
            <p className="mb-4">
              We may share your information with trusted third-party services
              for payment processing or analytics. These third parties are bound
              by confidentiality agreements.<br></br> We may share your
              information with Legal Authorities, If required by law or to
              protect the rights of NixArcade and its users.
            </p>
            <h3 className="text-xl font-bold mt-6 mb-2">
              5. Cookies and Tracking Technologies
            </h3>
            <p>
              NixArcade uses cookies to:
              <ul>
                <li>Enhance user experience.</li>
                <li>Analyze platform performance and usage trends.</li>
                <li>
                  You can manage cookie preferences through your browser
                  settings.
                </li>
              </ul>
            </p>
            <h3 className="text-xl font-bold mt-6 mb-2">6. Your Rights</h3>
            <p className="mb-4">
              You have the right to access, modify, or delete your personal
              data. To exercise these rights, contact us at
              support@nixarcade.fun.
            </p>
            <h3 className="text-xl font-bold mt-6 mb-2">
              7. Retention of Information
            </h3>
            <p>
              We retain user data only as long as necessary to fulfill the
              purposes outlined in this policy, unless a longer retention period
              is required by law.
            </p>
          </section>

          {/* Contact Information */}
          <section>
            <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
            <p className="mb-4">
              If you have any questions or concerns about our Terms of Service
              or Privacy Policy, reach out to us at:
            </p>
            <p className="font-bold">Email:</p>
            <p>support@nixarcade.fun</p>
          </section>
        </div>
      </div>
    </>
  );
}
