import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./components/Landing";
import { TermsOfService } from "./components/Terms";
import FAQPage from "./Faq";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<TermsOfService />} />
        <Route path="/faqs" element={<FAQPage />} />
      </Routes>
    </Router>
  );
}

export default App;
